import { Ref } from 'vue';
import { useRoute } from 'vue-router';

import { CmsiApi } from '@/api';
import {
  AreaInfoIdEnum,
  CmSalesImpactProductsDetails,
  CmSalesImpactReportSettingsForm
} from '@/api/openapi';

import useLoading from '@/composables/loading';
import { toast } from '@/components/ui/Toast';

import {
  SelectValueReachBasisWeek,
  SelectValueReachBasisFrequency,
  Select,
  Area,
  Save
} from '../types';

interface FormValue {
  areaValue: Ref<AreaInfoIdEnum>;
  selectValueReachBasisWeek: Ref<Select>;
  selectValueReachBasisFrequency: Ref<Select>;
}

interface CmsiProductSetting
  extends Area,
    SelectValueReachBasisWeek,
    SelectValueReachBasisFrequency,
    Save {
  form: FormValue;
}

import {
  useSelectValueReachBasisWeek,
  useSelectValueReachBasisFrequency,
  useSimpleArea
} from '@/composables/campaign/cmsi/product/reportCreate';

export const useCmsiProductSetting = ({
  product
}: {
  product: CmSalesImpactProductsDetails;
}): CmsiProductSetting => {
  const route = useRoute();
  const { params } = route;
  const companyId = Number(params.companyId);

  // リーチ基準
  const {
    selectValueReachBasisWeek,
    optionsReachBasisWeek
  }: SelectValueReachBasisWeek = useSelectValueReachBasisWeek({
    init: product.reachWeeks
  });
  selectValueReachBasisWeek.value = optionsReachBasisWeek.value.filter(
    v => v.value === product.reachWeeks
  )[0];
  const {
    selectValueReachBasisFrequency,
    optionsReachBasisFrequency
  }: SelectValueReachBasisFrequency = useSelectValueReachBasisFrequency({
    init: product.reachFrequency
  });
  selectValueReachBasisFrequency.value = optionsReachBasisFrequency.value.filter(
    v => v.value === product.reachFrequency
  )[0];

  // エリア
  const { areaList, areaValue, isLoadingArea }: Area = useSimpleArea({
    companyId,
    productId: product.productId,
    init: product.areaCode
  });
  // 保存
  const { isLoading, onSave }: Save = useSave();

  const form: FormValue = {
    areaValue,
    selectValueReachBasisWeek,
    selectValueReachBasisFrequency
  };

  return {
    selectValueReachBasisWeek,
    optionsReachBasisWeek,
    //
    selectValueReachBasisFrequency,
    optionsReachBasisFrequency,
    //
    areaList,
    areaValue,
    isLoadingArea,
    //
    isLoading,
    onSave,
    //
    form
  };
};

// 編集
const useSave = (): Save => {
  const [isLoading, post] = useLoading(_postSave);

  return {
    isLoading: isLoading,
    onSave: post
  };
};
const _postSave = async ({ form }) => {
  try {
    await CmsiApi.postCmSalesImpactReportSettings(form);
    toast({
      title: '設定を変更しました',
      variant: 'success'
    });
  } catch (e) {
    toast({
      title: '設定保存失敗しました',
      message: e.message,
      variant: 'error'
    });
  }
};
// APIに合わせるformat
export const formatForm = ({
  form,
  productId
}: {
  form: FormValue;
  productId: number;
}): CmSalesImpactReportSettingsForm => {
  // 整形
  const data: CmSalesImpactReportSettingsForm = {
    productId,
    areaCode: form.areaValue.value,
    reachFrequency: form.selectValueReachBasisFrequency.value.value,
    reachWeeks: form.selectValueReachBasisWeek.value.value
  };
  return data;
};

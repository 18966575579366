import { ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { COMPANY_ROUTES, CAMPAIGN_ROUTES } from '@/router';

import { CmSalesImpactProductsDetails } from '@/api/openapi';

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: '', label: 'CMセールスインパクト' },
    {
      name: CAMPAIGN_ROUTES.cmsi,
      label: '売上効果分析',
      query: {
        mode: 'static'
      }
    },
    {
      name: CAMPAIGN_ROUTES.cmsiProduct,
      label: '',
      param: { productId: 0 }
    }
  ],
  current: { label: '編集' }
};

interface Edit {
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
  pageTitle: Ref<string>;
  companyId: Ref<number>;
}

export const useEdit = ({
  product
}: {
  product: CmSalesImpactProductsDetails;
}): Edit => {
  const route = useRoute();
  const { params } = route;
  const pageTitle = ref('');
  const companyId = ref(Number(params.companyId));
  const productId = ref(Number(params.productId));
  const breadcrumbs = ref(BREADCRUMBS);

  (async () => {
    if (product.productName) {
      const name = product.productName;
      pageTitle.value = `${name} 編集`;
      const { title } = document;
      document.title = name + ' ' + title;
      // パンくずの書き換え
      breadcrumbs.value.parents.slice(-1)[0].label = name;
      (breadcrumbs.value.parents.slice(-1)[0].param as {
        productId: number;
      }).productId = productId.value;
    }
  })();

  return {
    breadcrumbs,
    pageTitle,
    companyId
  };
};
